<template>
  <div class="sfe__block sfe__comfortable">
    <div class="sfe__comfortable__text">
      <h3 class="title-secondary">
        <span>Удобная платформа</span>
      </h3>
      <p class="text-primary">
        Собственная образовательная платформа с&nbsp;авторскими учебными материалами,
        где ученик выполняет домашки и&nbsp;общается со&nbsp;сверстниками
      </p>

      <div class="sfe__comfortable_btns">
        <button
          v-for="(item, index) in videoSlides"
          :key="index"
          :class="['m-btn', { 'sfe__comfortable_tab__active': index === currentSlideIndex }]"
          @click="selectByBtn(index)"
        >
          {{ item.title }}
        </button>
      </div>

      <div class="sfe__comfortable__access m-btn__pull">
        <button
          @click="toggleLead1Modal"
        >
          Получить доступ
        </button>
      </div>
    </div>

    <div class="sfe__comfortable__slider">
      <div class="sfe__comfortable__slider_controls">
        <button
          class="m-btn"
          @click="prevSlide"
        >
          <img
            loading="lazy"
            src="/v2/main/arrow-left.svg"
            alt="prev"
          >
        </button>
        <button
          class="m-btn"
          @click="nextSlide"
        >
          <img
            loading="lazy"
            src="/v2/main/arrow-right.svg"
            alt="next"
          >
        </button>
      </div>

      <div class="sfe__comfortable__slide">
        <div
          v-if="!showVideo"
          class="sfe__comfortable__slide_preview"
        >
          <img
            :src="currentSlide.preview"
            :alt="currentSlide.title"
            class="sfe__comfortable__slide_preview--image"
          >
          <img
            src="/v2/main/play.svg"
            alt="play"
            class="sfe__comfortable__slide_preview--play"
            @click="openVideo"
          >
        </div>
        <div
          v-else
          style="width: 100%; height: 100%;"
        >
          <iframe
            v-if="currentSlide.youtube"
            class="sfe__comfortable__slide_video"
            :src="currentSlide.video"
            :title="currentSlide.title"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; web-share; autoplay"
            allowfullscreen
          />
          <video
            v-else
            class="sfe__comfortable__slide_video"
            :poster="currentSlide.preview"
            controls
            :src="currentSlide.video"
            autoplay
            playsinline
            preload="none"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SfeComfortable',
  props: {
    initialSlideIndex: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    videoSlides: [
      {
        title: 'Записи уроков',
        preview: '/v2/main/sfe/comfortable/record.webp',
        // eslint-disable-next-line max-len,vue/max-len
        video: 'https://www.youtube.com/embed/RASkhQRHVpc?mute=0&autoplay=1&enablejsapi=1&si=kC4DLZBKFlh5k3kf&amp;start=910',
        youtube: true,
      },
      {
        title: 'Экспресс лекции',
        preview: '/v2/main/sfe/comfortable/express.webp',
        // eslint-disable-next-line max-len,vue/max-len
        video: 'https://www.youtube.com/embed/9YgdaxWz8BQ?mute=0&autoplay=1&enablejsapi=1&si=APhjavIUwdnD3wOg',
        youtube: true,
      },
      {
        title: 'Домашки',
        preview: '/v2/main/sfe/comfortable/hw.webp',
        video: '/v2/videos/Домашка и Контрольные.mp4',
        youtube: false,
      },
      {
        title: 'Интерактивные задания',
        preview: '/v2/main/sfe/comfortable/interactive.webp',
        video: '/v2/videos/интерактивн задание.mp4',
        youtube: false,
      },
      {
        title: 'Лабораторки',
        preview: '/v2/main/sfe/comfortable/lab.webp',
        video: '/v2/videos/Лабораторки.mp4',
        youtube: false,
      },
      {
        title: 'Контрольные',
        preview: '/v2/main/sfe/comfortable/cw.webp',
        video: '/v2/videos/Домашка и Контрольные.mp4',
        youtube: false,
      },
    ],
    currentSlideIndex: 0,
    showVideo: false,
  }),
  computed: {
    currentSlide() {
      return this.videoSlides[this.currentSlideIndex];
    },
  },
  created() {
    this.currentSlideIndex = this.initialSlideIndex;
  },
  methods: {
    ...mapActions('modals', {
      toggleLead1Modal: 'toggleLead1',
    }),
    openVideo() {
      this.showVideo = true;
    },
    nextSlide() {
      if (this.currentSlideIndex === this.videoSlides.length - 1) {
        this.currentSlideIndex = 0;
      } else {
        this.currentSlideIndex += 1;
      }
      this.showVideo = false;
    },
    prevSlide() {
      if (this.currentSlideIndex === 0) {
        this.currentSlideIndex = this.videoSlides.length - 1;
      } else {
        this.currentSlideIndex -= 1;
      }
      this.showVideo = false;
    },
    selectByBtn(idx) {
      this.currentSlideIndex = idx;
      this.showVideo = false;
    },
  },
};
</script>

<style scoped lang="scss">
.sfe__block {
  padding: 35px 40px 30px;
  background-color: $white-color;
  border-radius: 20px;
  border: 2px solid $black;
  box-shadow: 4px 4px 0 0 $black;
  height: 580px;

  @include media-down(laptop) {
    height: auto;
    min-height: 500px;
  }

  @include media-down(tablet) {
    min-height: auto;
    box-shadow: 2px 4px 0 0 $black;
    border-radius: 16px;
    padding: 16px;
  }

  .title-secondary {
    letter-spacing: -1.35px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;

    @include media-down(tablet) {
      max-width: 280px;
      margin-bottom: 8px;
    }

    span {
      position: relative;
      z-index: 2;
    }

    &:before,
    &:after {
      content: '';
      height: 32px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 4px;

      @include media-down(tablet) {
        top: 2px;
        height: 23px;
      }
    }
  }

  .text-primary {
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: -0.2px;
    position: relative;
    z-index: 2;
  }
}

.sfe__comfortable {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 35px;

  @include media-down(laptop) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  @include media-down(tablet) {
    padding-bottom: 0;
    overflow: hidden;
  }

  .sfe__comfortable__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h3:before {
    width: 490px;
    background-color: #fb836f;

    @media (max-width: 1089px) {
      width: 210px;
    }

    @include media-down(laptop) {
      width: 490px;
    }

    @include media-down(tablet) {
      width: 135px;
    }
  }

  h3:after {
    @media (max-width: 1089px) {
      top: 55px;
      width: 280px;
      background-color: #fb836f;
    }

    @include media-down(laptop) {
      width: 0;
    }

    @include media-down(tablet) {
      width: 183px;
      top: 36px;
    }
  }

  p {
    max-width: 530px;
    margin-bottom: 30px;
  }

  .sfe__comfortable_btns {
    max-width: 400px;

    button {
      padding: 8px 18px 12px;
      gap: 10px;
      border-radius: 50px;
      border: 2px dashed $black;
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: 4px;
      margin-right: 2px;
      background-color: transparent;
      user-select: none;
      cursor: pointer;
      transition: 200ms;

      &:hover {
        border-style: solid;
      }

      &::selection {
        background: red;
      }
    }

    .sfe__comfortable_tab__active {
      background-color: $blue;
      border-style: solid;
    }
  }

  .sfe__comfortable__try--mobile {
    display: none;

    color: $black;
    font-size: 18px;
    font-weight: 700;
    line-height: 110%;
    padding: 9px 18px 11px;
    border-radius: 50px;
    border: 2px solid $black;
    background: $white-color;
    box-shadow: 2px 4px 0 0 $black;
    margin-bottom: 32px;

    @include media-down(laptop) {
      display: block;
    }
  }

  .sfe__comfortable__try {
    margin-top: auto;
    margin-bottom: 8px;
    text-align: center;
    color: $black;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.2px;
    width: 315px;

    @include media-down(laptop) {
      display: none;
    }
  }

  .sfe__comfortable__access {
    border-radius: 200px;
    display: block;
    margin-top: auto;

    @include media-down(laptop) {
      display: none;
    }

    button {
      padding: 20px 42px 26px 42px;
      border-radius: 200px;
      border: 2px solid $black;
      background-color: #ff7233;

      color: $black;
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      line-height: 100%;
      position: relative;
      z-index: 2;
    }

    @include media-down(laptop) {
      button {
        background-color: $white-color;
        padding: 10px 18px 12px;
        font-size: 18px;
        box-shadow: 2px 4px 0 0 $black;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 116px;
      height: 55px;
      position: absolute;
      top: -33px;
      right: -26px;
      background-image: url('/v2/school/advantages/free-sticker.webp');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.sfe__comfortable__slider {
  width: 600px;
  flex-shrink: 0;

  transform: translate(3px, -7px);

  @include media-down(desktop) {
    width: 500px;
    transform: none;
  }

  @include media-down(laptop) {
    width: 100%;
  }

  .sfe__comfortable__slider_controls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 26px;
    padding-right: 2px;

    @include media-down(laptop) {
      display: none;
    }

    button {
      transition: 100ms;
      filter: drop-shadow(2px 2px 0 $black);

      img {
        background-color: $white-color;
        border-radius: 50%;
      }

      &:hover {
        filter: none;
        transform: translate(2px, 2px);
      }
    }
  }

  .sfe__comfortable__slide {
    width: 100%;
    aspect-ratio: 10 / 7;
    overflow: hidden;
    border-radius: 20px;
    border: 2px solid $black;
    background-color: #f5f5f5;

    @include media-down(tablet) {
      margin-bottom: -25px;
      padding-bottom: 20px;
    }
  }

  .sfe__comfortable__slide_preview {
    position: relative;

    &--image {
      width: 100%;
    }

    &--play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  .sfe__comfortable__slide_video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    background-color: $black-color;
  }
}
</style>
