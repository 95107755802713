<template>
  <div
    class="gos-licenses"
    @click="$emit('close')"
  >
    <button
      class="gos-licenses__close m-btn"
      @click="$emit('close')"
    >
      <img
        src="/v2/main/sfe/license-close.svg"
        alt="close"
      >
    </button>

    <button
      v-show="currentSlide !== 1"
      class="gos-licenses__prev m-btn"
      @click.stop="prevSlide"
    >
      <img
        src="/v2/main/sfe/arrow-down.svg"
        alt="prev"
      >
    </button>
    <button
      v-show="currentSlide !== 3"
      class="gos-licenses__next m-btn"
      @click.stop="nextSlide"
    >
      <img
        src="/v2/main/sfe/arrow-down.svg"
        alt="next"
      >
    </button>

    <div
      :class="['gos-licenses__slider', `gos-licenses__slider--${currentSlide}`]"
      @click.stop
    >
      <img
        class="gos-licenses__slide"
        src="/v2/main/sfe/license-1.webp"
        alt="license 1"
      >
      <img
        class="gos-licenses__slide"
        src="/v2/main/sfe/license-2.webp"
        alt="license 2"
      >
      <img
        class="gos-licenses__slide pointer"
        src="/v2/main/sfe/license-3.webp"
        alt="license 3"
        @click.stop="openDoc"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'GosLicensesModal',
  data: () => ({
    currentSlide: 1,
  }),
  mounted() {
    document.body.style.overflow = 'hidden';
    window.addEventListener('keydown', this.keyDownHnd);
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
    window.removeEventListener('keydown', this.keyDownHnd);
  },
  methods: {
    keyDownHnd(e) {
      if (e.key === 'Escape') {
        this.$emit('close');
      }
    },

    nextSlide() {
      if (this.currentSlide === 3) {
        this.currentSlide = 1;
      } else {
        this.currentSlide += 1;
      }
    },
    prevSlide() {
      if (this.currentSlide === 1) {
        this.currentSlide = 3;
      } else {
        this.currentSlide -= 1;
      }
    },
    openDoc() {
      window.open('/v2/main/sfe/Реестровая выписка.pdf', '_blank');
    },
  },
};
/* eslint-disable */
</script>

<style scoped lang="scss">

.gos-licenses {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 51;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#242424, 0.3);
    backdrop-filter: blur(2.5px);
  }

  button {
    position: absolute;
    z-index: 6;
  }

  .gos-licenses__close {
    top: 16px;
    right: 30px;
    transition: 200ms;

    @include media-down(mobile) {
      right: 0;
      top: 0;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .gos-licenses__prev,
  .gos-licenses__next {
    right: 30px;

    background-color: $white-color;
    border-radius: 50%;
    width: 44px;
    height: 44px;

    @include media-down(mobile) {
      width: 32px;
      height: 32px;
      right: 8px;
    }
  }

  .gos-licenses__prev {
    bottom: 174px;
    transform: rotate(180deg);

    @include media-down(mobile) {
      bottom: 54px;
    }
  }

  .gos-licenses__next {
    bottom: 120px;

    @include media-down(mobile) {
      bottom: 8px;
    }
  }

  .gos-licenses__slide {
    width: 100%;
    aspect-ratio: 13 / 19;

    border-radius: 20px;
    border: 2px solid #242424;
    filter: drop-shadow(10px 10px 50px rgba(7, 21, 57, 0.1)) drop-shadow(4px 4px 20px rgba(7, 21, 57, 0.05));
    object-fit: cover;
  }

  .gos-licenses__slider {
    $fullWidth: 468px;
    $laptopWidth: 350px;
    $tabletWidth: 280px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: $fullWidth;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    transition: 300ms;

    @include media-down(laptop) {
      width: $laptopWidth;
    }

    @include media-down(tablet) {
      gap: 12px;
      width: $tabletWidth;
    }

    // для desktop
    $licenceHeight: calc(($fullWidth / 13) * 19);
    $topOffset: calc((100vh - $licenceHeight) / 2);

    &--1 {
      top: $topOffset;
    }

    &--2 {
      $topOffset2: calc($topOffset - $licenceHeight - 20px);
      top: $topOffset2;
    }

    &--3 {
      $topOffset3: calc($topOffset - $licenceHeight * 2 - 40px);
      top: $topOffset3;
    }

    // для laptop
    @include media-down(laptop) {
      $licenceHeight: calc(($laptopWidth / 13) * 19);
      $topOffset: calc((100vh - $licenceHeight) / 2);

      &--1 {
        top: $topOffset;
      }

      &--2 {
        $topOffset2: calc($topOffset - $licenceHeight - 20px);
        top: $topOffset2;
      }

      &--3 {
        $topOffset3: calc($topOffset - $licenceHeight * 2 - 40px);
        top: $topOffset3;
      }
    }

    // для tablet
    @include media-down(tablet) {
      $licenceHeight: calc(($tabletWidth / 13) * 19);
      $topOffset: calc((100vh - $licenceHeight) / 2);

      &--1 {
        top: $topOffset;
      }

      &--2 {
        $topOffset2: calc($topOffset - $licenceHeight - 12px);
        top: $topOffset2;
      }

      &--3 {
        $topOffset3: calc($topOffset - $licenceHeight * 2 - 24px);
        top: $topOffset3;
      }
    }
  }
}

</style>
